import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("mnqkzvbd");
  if (state.succeeded) {
      return <p className="formSuccess"> Thanks for reaching out to us! We will contact you shortly. </p>;
  }
  return (
    <div>
      {" "}
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        CONTACT US{" "}
      </h1>
      <div className=" flex flex-col justify-center md:pt-12 pb-32 bg-white">
        <div className=" bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-6 md:p-14 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-left">
            {/* <h3 className="font-montserrat font-semibold text-black text-left pb-3 text-xl tracking-wide">
              CONTACT
            </h3> */}
            <p className=" text-left .leading-8  font-montserrat font-medium tracking-narrow pb-5">
            Your thoughts and inquiries matter to us! We're here to assist you. Please don't hesitate to reach out by filling in the email form below. Whether you have questions, feedback, or just want to say hello, we're eager to hear from you. Thank You
            </p>
            <p className=" text-left .leading-8  font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pr-2"
                icon={faPhone}
                style={{ color: "#E5B50B" }}
              />{" "}
              604 213 5232
            </p>
            <p className=" text-left .leading-8  font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pr-2"
                icon={faEnvelope}
                style={{ color: "#E5B50B" }}
              />{" "}
              Info@varsa.ca
            </p>
            <p className=" flex  text-left .leading-8  font-montserrat font-black tracking-narrow pb-5">
              <FontAwesomeIcon
                className="pl-1 pr-2 py-1"
                icon={faLocationDot}
                style={{ color: "#E5B50B" }}
              />{" "}
              105 - 15292 Croydon Drive, Surrey, British Columbia V3Z 0Z5,
              Canada
            </p>
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-3/4 text-left flex flex-col space-y-2 md:">
          
            <form onSubmit={handleSubmit}>
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    for="first_name"
                    class="block mb-2 font-montserrat font-medium "
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="name"
                    class="bg-gray-50 border border-[#E5B50B] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="First name"
                    required
                  />
                </div>
                <div>
                  <label
                    for="last_name"
                    class="block mb-2 font-montserrat font-medium "
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="name"
                    class="bg-gray-50 border border-[#E5B50B] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 font-montserrat font-medium "
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="_replyto"
                    class="bg-gray-50 border border-[#E5B50B] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="name@mail.com"
                    required
                  />
                </div>
                <div>
                  <label
                    for="phone"
                    class="block mb-2 font-montserrat font-medium "
                  >
                    Phone number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    class="bg-gray-50 border border-[#E5B50B] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="123-456-7891"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    required
                  />
                </div>
                <div>
                  <label
                    for="message"
                    class="block mb-2 font-montserrat font-medium "
                  >
                    Message
                  </label>
                  <textarea
                    type="textarea"
                    id="message"
                    name="message"
                    class="bg-gray-50 border border-[#E5B50B] text-sm  focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 "
                    placeholder="Enter your message"
                    required
                  />
                </div>
              </div>
              <button
                type="submit" disabled={state.submitting}
                class="bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 "
              >
                SEND MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

