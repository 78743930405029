import React from "react";
import 'swiper/css'; // Core Swiper CSS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'; // Correct import for modules

import 'swiper/css/navigation'; // Navigation module CSS
import 'swiper/css/pagination'; // Pagination module CSS

// Import images for each project
import madrona1 from "./../assets/gallery/madrona1.jpg";
import madrona2 from "./../assets/gallery/madrona2.jpg";
import madrona3 from "./../assets/gallery/madrona3.jpg";
import madrona4 from "./../assets/gallery/madrona4.jpg";
import madrona5 from "./../assets/gallery/madrona5.jpg";
import madrona6 from "./../assets/gallery/madrona6.jpg";
import madrona7 from "./../assets/gallery/madrona7.jpg";
import madrona8 from "./../assets/gallery/madrona8.jpg";
import morgan1 from "./../assets/gallery/morgan1.jpg";
import morgan2 from "./../assets/gallery/morgan2.jpg";
import morgan3 from "./../assets/gallery/morgan3.jpg";
import morgan4 from "./../assets/gallery/morgan4.jpg";
import morgan6 from "./../assets/gallery/morgan6.jpg";
import morgan7 from "./../assets/gallery/morgan7.jpg";
import morgan8 from "./../assets/gallery/morgan8.jpg";
import morgan9 from "./../assets/gallery/morgan9.jpg";
import morgan10 from "./../assets/gallery/morgan10.jpg";
import morgan11 from "./../assets/gallery/morgan11.jpg";
import morgan12 from "./../assets/gallery/morgan12.jpg";
import morgan13 from "./../assets/gallery/morgan13.jpg";
import morgan14 from "./../assets/gallery/morgan14.jpg";
import morgan15 from "./../assets/gallery/morgan15.jpg";
import narrows1 from "./../assets/gallery/narrows1.jpg";
import narrows2 from "./../assets/gallery/narrows2.jpg";
import narrows3 from "./../assets/gallery/narrows3.jpg";
import narrows4 from "./../assets/gallery/narrows4.jpg";
import narrows5 from "./../assets/gallery/narrows5.jpg";
import narrows6 from "./../assets/gallery/narrows6.jpg";
import lionsbayext1 from "./../assets/gallery/lionsbayext1.jpg";
import lionsbayext3 from "./../assets/gallery/lionsbayext3.jpg";
import lionsbayext4 from "./../assets/gallery/lionsbayext4.jpg";
import lionsbayext5 from "./../assets/gallery/lionsbayext5.jpg";
import lionsbay1 from "./../assets/gallery/lionsbay1.jpg";
import lionsbay2 from "./../assets/gallery/lionsbay2.jpg";
import lionsbay3 from "./../assets/gallery/lionsbay3.jpg";
import lionsbay4 from "./../assets/gallery/lionsbay4.jpg";
import lionsbay5 from "./../assets/gallery/lionsbay5.jpg";
import lionsbay6 from "./../assets/gallery/lionsbay6.jpg";
import lionsbay7 from "./../assets/gallery/lionsbay7.jpg";
import lionsbay8 from "./../assets/gallery/lionsbay8.jpg";
import craft1 from "./../assets/gallery/craft1.jpg";
import craft2 from "./../assets/gallery/craft2.jpg";
import craft3 from "./../assets/gallery/craft3.jpg";
import craft4 from "./../assets/gallery/craft4.jpg";
import craft5 from "./../assets/gallery/craft5.jpg";
import craft6 from "./../assets/gallery/craft6.jpg";
import craft7 from "./../assets/gallery/craft7.jpg";
import craft8 from "./../assets/gallery/craft8.jpg";
import craft9 from "./../assets/gallery/craft9.jpg";
import craft10 from "./../assets/gallery/craft10.jpg";
import craft11 from "./../assets/gallery/craft11.jpg";
import eHastings1 from "./../assets/gallery/eHastings1.jpg";
import eHastings2 from "./../assets/gallery/eHastings2.jpg";
import eHastings3 from "./../assets/gallery/eHastings3.jpg";
import eHastings4 from "./../assets/gallery/eHastings4.jpg";
import eHastings5 from "./../assets/gallery/eHastings5.jpg";
import eHastings6 from "./../assets/gallery/eHastings6.jpg";
import eHastings7 from "./../assets/gallery/eHastings7.jpg";
import eHastings8 from "./../assets/gallery/eHastings8.jpg";
import eHastings9 from "./../assets/gallery/eHastings9.jpg";
import eHastings10 from "./../assets/gallery/eHastings10.jpg";



// Data for projects
const madronaImages = [
  { url: madrona1 },
  { url: madrona2 },
  { url: madrona3 },
  { url: madrona4 },
  { url: madrona5 },
  { url: madrona6 },
  { url: madrona7 },
  { url: madrona8 },

];

const morganImages = [
  { url: morgan1 },
  { url: morgan2 },
  { url: morgan3 },
  { url: morgan4 },
  { url: morgan6 },
  { url: morgan7 },
  { url: morgan8 },
  { url: morgan9 },
  // { url: morgan10 },
  // { url: morgan11 },
  // { url: morgan12 },
  // { url: morgan13 },
  { url: morgan14 },
  { url: morgan15 },
];


const narrowsImages = [
  { url: narrows1 },
  { url: narrows2 },
  { url: narrows3 },
  { url: narrows4 },
  { url: narrows5 },
  { url: narrows6 },
];

const lionsbayImages = [
  { url: lionsbayext1 },
  { url: lionsbayext3 },
  { url: lionsbayext4 },
  { url: lionsbayext5 },
  { url: lionsbay1 },
  { url: lionsbay2 },
  { url: lionsbay3 },
  { url: lionsbay4 },
  { url: lionsbay5 },
  { url: lionsbay6 },
  { url: lionsbay7 },
  { url: lionsbay8 },
];

const craftImages = [
  { url: craft1 },
  { url: craft2 },
  { url: craft3 },
  { url: craft4 },
  { url: craft5 },
  { url: craft6 },
  { url: craft7 },
  { url: craft8 },
  { url: craft9 },
  { url: craft10 },
  { url: craft11 },
];

// Data for 2601 East Hastings project
const eHastingsImages = [
  { url: eHastings2 },
  { url: eHastings1 },
  { url: eHastings3 },
  { url: eHastings4 },
  { url: eHastings5 },
  { url: eHastings6 },
  { url: eHastings7 },
  { url: eHastings8 },
  { url: eHastings9 },
  { url: eHastings10 },
];


const ProjectSwiper = ({ projectName, images }) => {
  return (
    <div className="my-8">
      <h2 className="text-3xl md:text-5xl text-center font-suranna font-medium tracking-wider mb-8">
        {projectName}
      </h2>
      <div className="max-w-xl md:max-w-6xl mx-auto">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="w-full bg-white grid place-items-center">
                <img
                  src={image.url}
                  alt={image.title}
                  className="object-cover w-full h-full"
                  style={{ aspectRatio: "5/3", width: "100%", height: "auto" }}
                />
                <p className="text-center font-medium mt-12">{image.title}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const Gallery = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        FEATURED PROJECTS
      </h1>

     

      {/* Lions Bay Swiper */}
      <ProjectSwiper projectName="Lions Bay" images={lionsbayImages} />

      {/* Craft Residence */}
      <ProjectSwiper projectName="Craft Residence" images={craftImages} />

      {/* Madrona House Swiper */}
      <ProjectSwiper projectName="Madrona Drive" images={madronaImages} />

      {/* Morgan Crossing */}
      <ProjectSwiper projectName="Morgan Creek" images={morganImages} />

      {/* The Narrows Swiper */}
      <ProjectSwiper projectName="The Narrows" images={narrowsImages} />

      {/* The Narrows Swiper */}
      <ProjectSwiper projectName="2601 East Hastings" images={eHastingsImages} />






    </div>
  );
};

export default Gallery;
