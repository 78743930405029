import React from "react";
import aboutCover2 from "./../assets/about/abcover.webp";
import brainstorm from "./../assets/about/brainstorm.webp";
import prototyping from "./../assets/about/prototyping.webp";
import completion from "./../assets/about/completion.webp";
import commitment from "./../assets/about/commitment.webp";
import teamMember1 from "./../assets/team/george.jpg"; // Import headshots
import teamMember2 from "./../assets/team/jag.jpg"; // Import headshots
import teamMember3 from "./../assets/team/henry.jpg"; // Import headshots
import teamMember4 from "./../assets/team/raj.jpg"; // Import headshots
import teamMember5 from "./../assets/team/cristina.jpg"; // Import headshots
import teamMember6 from "./../assets/team/sandra.jpg"; // Import headshots
import teamMember7 from "./../assets/team/taha.jpg"; // Import headshots

import { Link } from "react-router-dom";

const teamMembers = [
  {
    name: "George Varnas",
    title: "CEO",
    img: teamMember1,
  },
  {
    name: "Jag Cheema",
    title: "Sales Executive",
    img: teamMember2,
  },
  {
    name: "Henry Randhawa",
    title: "Site Super",
    img: teamMember3,
  },
  {
    name: "Raj Grewal",
    title: "Lead Engineer",
    img: teamMember4,
  },
  {
    name: "Cristina Dietrich",
    title: "Operations",
    img: teamMember5,
  },
  {
    name: "Sandra Guerrero",
    title: "Glazing Specialist",
    img: teamMember6,
  },
  {
    name: "Taha Shaikh",
    title: "Project Manager",
    img: teamMember7,
  }
  // Add more team members as needed
];



const About = () => {
  return (
    <div>
      <h1 className="text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        ABOUT US
      </h1>

      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-32 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={aboutCover2}
              alt="Varsa cover image"
              className="object-cover w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              WE ARE A WINDOWS & DOORS MANUFACTURER
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              At Varsa Windows and Doors, we are more than just a business – we
              are a testament to our Greek heritage and a celebration of our
              roots. Since our establishment in 2006, we have been dedicated to
              crafting and delivering exceptional windows and doors. What began
              as a manufacturing and wholesale operation has grown into a
              comprehensive service, encompassing sales and professional
              installation since 2012. We take
              immense pride in providing outstanding customer service while
              delivering high-end quality products. Our Greek heritage inspires
              us to infuse our craftsmanship with elegance, durability, and a
              touch of Mediterranean charm. Join us on this journey as we
              combine tradition with innovation to transform your spaces.
            </p>
          </div>
        </div>
      </div>

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        WHAT WE DO
      </h2>

      <div className=" flex flex-col justify-center  md:pb-32 pb-12 bg-white">
        <div className=" bg-[#E5B50B] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-4xl mx-auto  ">
          <div className=" bg-[#F1F1F1] p-6 w-full md:w-3/5  flex flex-col space-y-2 md:p-10">
            <p className=" text-left .leading-8  font-montserrat font-medium md:text-base tracking-narrow pb-5">
              As a business with over 60 years of combined generational experience, we offer a
              wealth of knowledge to offer you our honest expert advice. We can
              assist you with a range of windows including window wall and
              curtain walls and doors.
            </p>

            <p className=" text-left .leading-8  font-montserrat font-medium md:text-base tracking-narrow pb-5">
              {" "}
              As a small business, we are passionate about sustainable housing
              and ecological footprint which is why everything we install
              results in ultra-low energy usage with our profile line meeting
              and exceeding the required u-value of 1.2 in Vancouver.
            </p>
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/5 grid place-items-left p-7 md:p-16">
            <h3 className="font-montserrat font-medium text-black text-left pb-3 text-lg tracking-wider">
              <h4 className="font-montserrat font-medium text-black text-left text-3xl md:text-5xl tracking-wide">
                60+
              </h4>
              Years of experience
            </h3>
            <h3 className="font-montserrat font-medium text-black text-left pb-3 text-lg tracking-wider">
              <h4 className="font-montserrat font-medium text-black text-left text-3xl md:text-5xl tracking-wide">
                500+
              </h4>
              Happy Clients
            </h3>
            <h3 className="font-montserrat font-medium text-black text-left pb-3 text-lg tracking-wider">
              <h4 className="font-montserrat font-medium text-black text-left text-3xl md:text-5xl tracking-wide">
                1000+
              </h4>
              Projects completed
            </h3>
          </div>
        </div>
      </div>

      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wide md:tracking-widest pb-12 md:p-32">
        OUR PROCESS
      </h2>

      <div className="  flex flex-col justify-center pb-12 md:pb-32">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-sm md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 grid place-items-center   ">
           

            <img
              src={brainstorm}
              alt="brain storm image"
              className="object-fit md:w-2/3 h-full"
            />
             <span className="font-montserrat font-bold text-center pt-6 text-sm tracking-wide ">
              BRAINSTORMING
            </span>   
            <p className=" text-left .leading-8  font-montserrat font-medium text-md tracking-narrow pt-5 pb-24 md:pb-0">
              We first meet with you to gather a complete understanding of your
              vision, taking into account the site location,
              features, and projected budget.
            </p>
          </div>

          <div className="  w-full md:3/3  grid place-items-center  ">
           
            <img
              src={[prototyping]}
              alt="prototyping image"
              className="object-fit md:w-2/3 h-full "
            />
      <span className="font-montserrat font-bold  text-center pt-6 text-sm tracking-wide ">
              PROTOTYPING
            </span>

            <p className=" text-left .leading-8  font-montserrat font-medium text-md tracking-narrow pt-5 pb-24 md:pb-0">
              Before the design stage, the project’s scope must be explored and
              understood. We have extensive experience working with many types
              of projects, areas, and materials.
            </p>
          </div>

          <div className=" w-full md:3/3  grid place-items-center ">

            <img
              src={completion}
              alt="completion image"
              className="object-fit md:w-2/3 h-full"
            />
  <span className="font-montserrat font-bold  text-center pt-6 text-sm tracking-wide ">
              COMPLETION
            </span>
            <p className="  text-left .leading-8  font-montserrat font-medium text-md tracking-narrow pt-5 pb-24 md:pb-0">
              During construction, we meet with both client and contractor as is
              necessary. Lastly we do the installation, ensuring it fits
              perfectly into the space.
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-12 md:pb-32 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" md:w-2/4 grid place-items-center">
            <img src={commitment} 
            alt="commitment image" 
            className="object-cover w-full h-full" />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 text-xl tracking-wide">
              OUR COMMITMENT
            </h3>
            <p className=" text-left .leading-8  font-montserrat font-medium  tracking-narrow pb-5">
              We emphasize design, quality, and craftsmanship in all of our
              work. We pride ourselves in creating elegantly beautiful, unique
              and functional Glass Installations to make your dream a reality.
              Contact us today to discuss your Custom Glazing needs.
            </p>
            <Link className="text-left" to="../contact">
                <button className=" bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 ">
                  GET IN TOUCH
                </button>
              </Link>
          </div>
        </div>
      </div>
{/* 
      <h2 className="text-4xl md:text-6xl text-center leading-8 font-suranna font-medium tracking-wçide md:tracking-widest pb-12 md:p-32">
        OUR TEAM
      </h2>

      <div className="flex flex-wrap justify-center bg-white pb-12 md:pb-32">
        <div className="max-w-sm md:max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center"
            >
              <img
                src={member.img}
                alt={`${member.name}'s headshot`}
                className="w-40 h-40 object-cover rounded-full"
              />
              <h3 className="font-montserrat font-semibold text-lg mt-4">
                {member.name}
              </h3>
              <p className="font-montserrat text-sm text-gray-600">
                {member.title}
              </p>
            </div>
          ))}
        </div>
      </div> */}




    </div>
  );
};

export default About;
