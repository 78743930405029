import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import doorsBlurb from "../../assets/doors/door2.webp";
import bifold from "../../assets/doors/door3.webp";
import lift from "../../assets/doors/door4.webp";
import slide from "../../assets/doors/door5.webp";
import entrance from "../../assets/doors/door6.webp";
import pivot from "../../assets/doors/Pivot.webp";
import swing from "../../assets/doors/door8.webp";
import "../Home.css";

const Doors = () => {
  return (
    <div>
      {" "}
      <h1 className="door-cover text-4xl md:text-7xl text-center leading-8 font-suranna font-medium tracking-widest p-12 md:p-32">
        DOORS
      </h1>
      <div className=" flex flex-col justify-center pb-12 md:pt-12 md:pb-32 bg-white">
        <div className="  bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
          <div className=" w-full md:w-2/4 grid place-items-center">
            <img
              src={doorsBlurb}
              alt="Doors cover"
              className="object-cover w-full"
            />
          </div>
          <div className=" bg-[#F1F1F1] w-full md:w-2/4 md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
            <h3 className="font-montserrat font-semibold text-black text-left pb-3 md:text-xl text-lg tracking-wide">
              OUR DOORS
            </h3>
            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              Experience the elegance of Greek heritage combined with unmatched
              craftsmanship at Varsa Windows & Doors. Our doors not only exude
              timeless charm but also surpass industry standards. With Step
              Quote 5 compliance and U-values exceeding 1.2, our products ensure
              top-notch security and energy efficiency. Discover the perfect
              blend of Greek tradition and exceptional performance with Varsa
              Windows & Doors.
            </p>

            <p className=" text-left .leading-8 md:text-base font-montserrat font-medium  tracking-narrow ">
              We carry Bifold Doors, Lift and Slide Doors, Sliding Doors, Pivot Doors, Patio Doors and Swing Doors
            </p>

          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-center pt-2 pb-2">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="bifold">
              <img
                src={bifold}
                alt="bifold door "
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              BIFOLD DOORS
            
            </span>
            
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="liftandslide">
              <img
                src={lift}
                alt="lift and slide door "
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              LIFT AND SLIDE DOORS
            </span>
          </div>
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
            <Link to="slider/s650">
              <img
                src={slide}
                alt="slide door"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              ULTRA SLIM LINE DOORS
            </span>
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-center pt-2 pb-2 md:pb-12">
        <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-xl md:max-w-6xl mx-auto bg-white ">
          <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="pivotdoors">
              <img
                src={pivot}
                alt="entrance door"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              PIVOT DOORS
            </span>
          </div>
          {/* <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
            <Link to="patio/e75">
              <img
                src={swing}
                alt="patio door"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              PATIO DOORS
            </span>
          </div> */}
          <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
            <Link to="swing">
              <img
                src={entrance}
                alt="swing door"
                className="object-cover w-full h-full"
              />
            </Link>
            <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
              SWING DOORS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doors;
