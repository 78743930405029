import React from "react";
import s560Cover from "../../../assets/s560/s560Cover.jpg";
import s560a from "../../../assets/s560/s560a.jpg";
import s560b from "../../../assets/s560/s560b.jpg";
import s560c from "../../../assets/s560/s560c.jpg";
import s560d from "../../../assets/s560/s560d.jpg";
import s560sec1 from "../../../assets/s560/s560sec1.png";
import s560sec2 from "../../../assets/s560/s560sec2.png";
import s560sec3 from "../../../assets/s560/s560sec3.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const S560 = () => {
  return (
    <div>
      <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32  bg-white">
        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-2/4  pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="text-4xl md:text-6xl md:text-center leading-8 font-suranna font-medium tracking-wider md:p-6  ">
              ALUMIL S560
            </h3>
            <h3 className="font-montserrat font-normal text-black md:text-center text- pb-3 text-md md:text-lg tracking-wide">
            lift & slide insulated system SMARTIA S560
            </h3>

            <div className=" md:hidden max-w-xl pb-6 ">
              <img
                src={s560Cover}
                alt="S560 Cover"
                className="object-cover w-full h-full"
              />
            </div>

            <div className=" md:p-12 md:pr-32 md:pl-32">
              <p className=" text-left .leading-8 font-montserrat font-medium md:text-base tracking-narrow pb-5">
              SMARTIA S560 is a robust, thermally insulated, lift & slide and regular sliding system, which provides high thermal performance, modern design and is ideal for constructions with wide spans.
              </p>
              <p className=" text-left .leading-8 font-montserrat font-semibold   md:text-lg tracking-narrow md:pb-2 pb-5">
                Advantages
              </p>
              <p className=" text-left .leading-8 font-montserrat font-medium   md:text-base tracking-narrow pb-5">
                <ul className="list-disc p-6">
                  <li>
                  Maximum natural light, with only 49 mm of visible aluminium face width at the interlocking profile
                  </li>
                  <li>High energy savings, thanks to the system's remarkable energy efficiency</li>
                  <li>Ease of use and high functionality, even for large spans</li>
                  <li>High quality with certified performance</li>
                </ul>
              </p>
            </div>
          </div>
          <div className=" hidden md:block md:w-2/4 pr-32 ">
            <img
              src={s560Cover}
              alt="S560 Cover"
              className="object-cover w-full h-full"
            />
          </div>
      </div>
      </div>
      <div className=" flex flex-col justify-center  pb-12  bg-white">
      <div className="hidden md:block w-full md:w-4/4 pt-2 flex flex-col space-y-2 mx-auto md:pb-12 ">
            <div class="md:container md:grid md:grid-cols-2 gap-2 mx-auto">
              <div class="w-full">
                <img src={s560a} alt="Alumil s560 example" />
              </div>
              <div class="w-full ">
                <img src={s560b} alt="Alumil s560 example" />
              </div>
              <div class="w-full ">
                <img src={s560c} alt="Alumil s560 example" />
              </div>
              <div class="w-full ">
                <img src={s560d} alt="Alumil s560 example" />
              </div>

        </div>
      </div>


        <div className=" md:container  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-full mx-auto  ">
          <div className=" w-full md:w-4/4  md:pt-2 md:p-6 flex flex-col space-y-2 md:pb-10 ">
            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TECHNICAL SPECS
            </h3>

            <div className=" md:block md:pb-12">
              <div className="grid md:grid-cols-4 md:pl-32 max-w-7xl font-montserrat md:text-base text-left pt-6 uppercase ">
              <span className="pr-10 font-semibold ">
                  UW
                  <span className="pr-10 block font-normal pb-4">
                    From 2,3 W/m2K
                  </span>
                </span>
                <span className="pr-10  font-semibold ">
                  AIR PERMEABILITY
                  <span className="pr-10 block font-normal pb-4 ">Class 4</span>
                </span>
                <span className="pr-10 font-semibold ">
                  WATER TIGHTNESS
                  <span className="pr-10 block font-normal pb-4">CLASS E1200</span>
                </span>
                <span className="pr-10 font-semibold ">
                  RESISTANCE TO WIND LOAD
                  <span className="pr-10 block font-normal pb-4">C5/B5</span>
                </span>
                <span className="pr-10 font-semibold ">
                  SOUND REDUCTION
                  <span className="pr-10 block font-normal pb-4">42 DB</span>
                </span>

                <span className="pr-10 font-semibold ">
                 BURGLAR RESISTANCE
                  <span className="pr-10 block font-normal pb-4">
                    {" "}
                   RC2
                  </span>
                </span>
              </div>
            </div>

            <h3 className="md:pl-32 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              TYPOLOGIES
            </h3>
            <p className=" md:pl-32 text-left .leading-8 font-montserrat font-medium  md:text-base tracking-narrow ">
              <ul className="list-disc p-6">
                <li>
                Parallel sliding, meeting style, Pocket Sliding and Corner Sliding
                </li>
              </ul>
            </p>

            <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
              SECTIONS
            </h3>
          </div>
        </div>
      </div>
      <div className="  flex flex-col justify-left pb-2">
        <div className="  md:max-w-8xl md:pl-32 md:pr-32 relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 md:pb-6   bg-white ">
          <div className="  md:w-full md:2/2 grid place-items-left  ">
            <img
              src={s560sec1}
              alt="s560 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-left  ">
            <img
              src={s560sec2}
              alt="s560 section"
              className="object-cover w-full h-full "
            />
          </div>
          <div className=" md:w-full md:2/2 grid place-items-left  ">
            <img
              src={s560sec3}
              alt="s560 section"
              className="object-cover w-full h-full "
            />
          </div>
        </div>
      </div>

      <h3 className="md:pl-32 md:pt-12 font-montserrat font-semibold text-left tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
        RELATED FILES
      </h3>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/pre-qualification-files/smartia/pre-q-smartia-s560-en.pdf?sfvrsn=15af6807_14"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S560 System Specs
          </a>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 p-3 md:pl-32 md:pt-4 items-center ">
        <div class="">
          <a
            href="https://www.alumil.com/docs/default-source/knowledgebase/brochures/technical/smartia/s560_en.pdf?sfvrsn=d1ced215_61"
            className="font-bold"
          >
            <FontAwesomeIcon
              className="pr-2 fa-3x"
              icon={faFilePdf}
              style={{ color: "#E5B50B" }}
            />{" "}
            S560 Brochure
          </a>
        </div>
      </div>

    </div>
  );
};

export default S560;
