
import React from "react";
import "./Home.css";
import IntroAnimation from "./IntroAnimation.js";
import aboutImg from "./../assets/home/abCover.webp";
import door from "./../assets/home/doors.webp";
import window from "./../assets/home/windows.webp";
import curtainWall from "./../assets/home/curtain.webp";
import projects from "./../assets/home/projects.webp";
import alumil from "./../assets/home/alumil.webp";
import elvial from "./../assets/home/elvial.webp";
import Testimonials from "./Testimonials.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMarker } from "@fortawesome/free-solid-svg-icons";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";
import { faPersonThroughWindow } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";



const Home = () => {
  return (
    <div>
      <main>

      <IntroAnimation /> {/* Use the IntroAnimation component */}

        <div class="hero bg-cover bg-center flex items-center pt-10 md:pt-10 flex-col h-screen">
          <h1 class=" text-[#36454F] text-left font-suranna text-4xl md:text-6xl md:pb-2 font-normal leading-8 tracking-widest uppercase">
            VARSA
          </h1>

          <p class="text-[#36454F]  text-left font-suranna pt-2 md:pt-4 text-lg md:text-lg font-light leading-8 tracking-wider uppercase">
            Windows & Doors
          </p>
        </div>

        <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32 md:pb-32 bg-white">
          <div className=" bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
            <div className="md:w-2/4 grid place-items-center">
              <img
                src={aboutImg}
                alt="Patio door"
                className="object-cover w-full h-full"
                loading="lazy"
              />
            </div>
          
            <div className=" bg-[#F1F1F1] w-full md:w-2/4  md:pt-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
              <div className=" font-montserrat font-black  flex justify-between item-center underline text-sm pb-2 md:pb-7">
                LEARN
              </div>
              <h3 className="font-montserrat font-semibold text-black text-left md:pb-3 text-lg md:text-xl tracking-wide">
                ABOUT US
              </h3>
              <p className=" text-left leading-8  font-montserrat font-medium  tracking-narrow pb-5">
                We are Varsa Windows and Doors and have been doing business
                since 2006. Originally, manufacturing and wholesaling of windows
                to window companies and as of 2012, selling and installing. We
                are a local family business that believes in excellent customer
                service along with high-end quality products.
              </p>
              <Link className="text-left" to="about">
                <button className=" bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 ">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className=" flex flex-col justify-center pt-12 md:pt-0 lg:pt-0 pb-4">
          <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
            COLLECTIONS
          </h3>
          <p className=" font-montserrat font-medium text-center md:space-x-5 space-y-3 md:space-y-0 max-w-full md:max-w-5xl mx-auto pr-10 pl-10 pt-6 pb-6 text-md ">
            Rooted in Greece's distinguished reputation for aluminum production,
            Varsa Windows and Doors proudly upholds the highest standards of
            quality and innovation in our craft.
          </p>
        </div>
        <div className="  flex flex-col justify-center pt-2 pb-2">
          <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-xl md:max-w-6xl mx-auto bg-white ">
            <div className="  w-full md:3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
              <Link to="doors">
                <img
                  src={door}
                  alt="Pivot Door"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
                DOORS
              </span>
            </div>
            <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 ">
              <Link to="windows">
                <img
                  src={window}
                  alt="European Window"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
                WINDOWS
              </span>
            </div>
            <div className=" w-full md:w-3/3 bg-white grid place-items-left transform transition duration-500 hover:scale-105 hover:">
              <Link to="curtain">
                <img
                  src={curtainWall}
                  alt="Curtain wall Image"
                  className="object-cover w-full h-full"
                />
              </Link>
              <span className="font-montserrat font-bold  text-left pb-3 text-sm tracking-wide pt-2">
                CURTAIN WALLS
              </span>
            </div>
          </div>
        </div>

        <div className=" flex flex-col justify-center pt-12 pb-12 md:pt-32 md:pb-32 bg-white">
          <div className=" bg-[#F1F1F1] relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 p-0 max-w-sm md:max-w-6xl mx-auto  ">
            <div className=" bg-[#F1F1F1] w-full md:w-2/4  md:pt-32 md:pb-20 md:pr-20 pt-2 p-6 flex flex-col space-y-2 md:p-10">
              <div className=" font-montserrat font-black  flex justify-between item-center underline text-sm pb-2 pt-6 md:pb-7">
                OUR WORK
              </div>
              <h3 className="font-montserrat font-semibold text-black text-left pb-3 text-lg md:text-xl tracking-wide">
                PROJECTS
              </h3>
              <p className=" text-left .leading-8  font-montserrat font-medium  tracking-narrow pb-5">
                Come explore all of our recent work ranging from custom doors.
                windows. curtain walls, passive housing, and more.
              </p>
              <Link className="text-left" to="projects">
                <button className=" bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 ">
                  Explore
                </button>
              </Link>
            </div>
            <div className=" md:w-2/4 grid place-items-center">
              <img
                src={projects}
                alt="Picture of a previous project"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>

        <div className=" flex flex-col justify-center">
          <h3 className="font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
            VARSA ADVANTAGE
          </h3>

          <div className="  flex flex-col justify-center pt-2 pb-2">
            <div className="  relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 max-w-xl md:max-w-6xl mx-auto  ">
              <div className="  w-full md:3/3 bg-white grid place-items-center md:p-20 pt-12 p-6">
                <FontAwesomeIcon
                  className="fa-6x pb-10 "
                  icon={faMarker}
                  style={{ color: "#000000" }}
                />

                <span className="font-montserrat font-bold text-center md:pb-3 text-sm tracking-wide pt-2">
                  HONEST ADVICE
                </span>
              </div>
              <div className=" w-full md:w-3/3 bg-white grid place-items-center md:p-20 p-6">
                <FontAwesomeIcon
                  className="fa-6x pb-10 "
                  icon={faCommentsDollar}
                  style={{ color: "#000000" }}
                />

                <span className="font-montserrat font-bold text-center md:pb-3 text-sm tracking-wide pt-2">
                  COMPETITIVE PRICING
                </span>
              </div>
              <div className=" w-full md:w-3/3 bg-white grid place-items-center md:p-20 p-6">
                <FontAwesomeIcon
                  className="fa-6x pb-10 "
                  icon={faPersonThroughWindow}
                  style={{ color: "#000000" }}
                />

                <span className="font-montserrat font-bold text-center md:pb-3 text-sm tracking-wide pt-2 ">
                  IN HOUSE INSTALLERS
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex flex-col justify-center">
          <h3 className="pt-12 md:pt-0 font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8">
            OUR PARTNERS
          </h3>

          <div className="  flex flex-col justify-center md:pt-2 pt-12 pb-2">
            <div className="  relative flex flex-row md:flex-row md:space-x-5 md:space-y-0 max-w-xl md:max-w-6xl mx-auto  ">
              <div className="  w-full md:3/3 bg-white grid place-items-center md:p-20 p-6">
                <img src={alumil} />
              </div>
              <div className=" w-full md:w-3/3 bg-white grid place-items-center md:p-20 p-6">
                <img src={elvial} />
              </div>
            </div>
          </div>
        </div>

        <div className=" flex flex-col justify-center">
          <h3 className="pt-12 pb-12 md:pt-0 font-montserrat font-semibold text-center tracking-widest text-black text-base underline decoration-4 decoration-[#E5B50B] leading-6 underline-offset-8 ">
            TESTIMONIALS
          </h3>

          <Testimonials />
          {/* <section className="text-black">
            <div className="max-w-screen-xl px-4 py-8 mx-auto text-center text-black lg:py-20 lg:px-6">
              <figure className="max-w-screen-md mx-auto text-black">
                <svg
                  className="h-12 mx-auto mb-3 text-black"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote>
                  <p className="md:text-2xl text-lg font-normal md:font-medium text-black">
                    "Varsa provided the best service I could ask for. Trained
                    technicians and awesome attitude made getting even the
                    hardest tasks completed a dream. I would encourage everybody
                    to trust Varsa with their next project!"
                  </p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                  <img
                    className="w-6 h-6 rounded-full"
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.webp"
                    alt="profile picture"
                  />
                  <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                    <div className="pr-3 font-medium text-black">
                      Lead Designer
                    </div>
                    <div className="pl-3 text-sm font-light text-black">
                      Northland Properties
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </section> */}
        </div>

        <section className="bg-[#F1F1F1] mt-12">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
              <h2 className="font-montserrat font-semibold mb-4 md:text-4xl text-lg tracking-tight font-extrabold leading-tight ">
                Contact us to find out more
              </h2>
              <p className="font-montserrat font-medium mb-6 font-light  md:text-lg">
                Upgrade your living spaces with Varsa Windows and Doors today!
                Discover the perfect blend of luxury, security, and
                Greek-inspired craftsmanship. Get a quote now!
              </p>
              <Link className="text-left" to="contact">
                <button className=" bg-black hover:bg-[#E5B50B] text-xs font-montserrat font-normal text-white py-2 px-4 w-full md:w-1/2 transform transition duration-500 ">
                  GET IN TOUCH
                </button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
